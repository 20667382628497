@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Otomanopee+One&display=swap');

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    min-height: 100vh;
    overflow-x: hidden;
}

.noScroll{
    overflow-y: hidden;
}

html {
    min-height: 100%;
}

h1 {
    font-family: "Otomanopee One", Roboto,sans-serif;
    font-size: 200%; /*correspond à 32px)*/
    font-weight: normal;
}

@media (max-width: 1020px) {
    h1 {
        font-size: 150%;
    }
}

h2 {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 700;
    font-size: 150%;  /*correspond à 24px*/
    letter-spacing: 1px;
}

/*@media (max-width: 1020px) {*/
/*    h2 {*/
/*        font-size: 100%; !*correspond à 32px*!*/
/*    }*/
/*}*/

.Mui-selected {
    background-color: #571088 !important;
}

.Mui-error {
    color: #495057 !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #ced4da !important;
}

.Mui-focused {
    color: #571088 !important;
}

.css-1mtsuo7 {
    z-index: 100000 !important;
}
