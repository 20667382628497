.listpoints::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #ddd;
    border-radius: 5px;
}

.listpoints::-webkit-scrollbar-thumb {
    background: #571088;
    border-radius: 5px;
}
