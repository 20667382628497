.navbar {
    background: #FFFFFF;
    padding: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 1000;
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Logo dans la navbar */

.logoContainer{
    text-align: left;
    display: inline-block;
    height: 50px;
    padding: 15px 60px 15px 20px;
}

@media screen and (max-width: 960px) {
    .logoContainer {
        padding: 15px 20px 15px 20px;
        flex: 1
    }
}

.logo {
    aspect-ratio: 500/160;
    height: 80%;
}

@media screen and (max-width: 960px) {
    .logo {
        width: 100%;
        object-fit: contain;
        object-position: left;
    }
}


/* Liens dans la navbar */

.link {
    color: #000;
    font-size: 1.1rem;
    font-weight: 500;
    margin: auto 10px auto 0;
    position: relative;
    padding: 13px 8px;
    text-decoration: none;
}

.link:hover {
    text-decoration: none;
}


.link:hover:after {
    background-color: #CE3534;
    width: 100%;
    height: 3px;
    content: "";
    bottom: 0;
    left: 0;
    display: block;
    z-index: 2;
    position: absolute;
}

.btn {
    padding: 10px 20px !important;
    font-size: 1.1rem !important;
}

.redBtn {
    background-color: #CE3534;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: white;
    margin-left: 15px;
    text-decoration: none;
}

.redBtn:hover {
    text-decoration: none;
    background-color: #C02727;
    color: white;
}

.leftBtn {
    right: 20px;
    position: absolute;
}

.mobileUserBtn {
    height: 70% !important;
    border: none;
    background: none;
    margin-left: 20px;
    color: #571088;
    width: auto;
    padding: 5px !important;
    justify-content: center;
    align-items: center;
    display: flex;
}

@media screen and (max-width: 960px) {
    .mobileUserBtn {
        display: unset;
        position: relative;
    }
}

.mobileUserBtn:hover {
    color: #4c0c78;
}

.leftBtnContainer {
    right: 20px;
    position: absolute;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    .leftBtnContainer {
        position: relative;
        flex: 1;
        margin-right: 15px;
        right: 0;
        justify-content: flex-end;
    }
}

.menu {
    font-size: 1.5rem !important;
    border: none;
    background: none;
}

@media screen and (max-width: 960px) {
    .menu {
        padding: 1px 6px;
    }
}

.dropdownMenu {
    z-index: 1000;
    background: white;
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 10%);
}

.mobileLink {
    color: #000000;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
}

.mobileBtn {
    padding: 10px 20px !important;
    font-size: 1.1rem !important;
}

.mobileRedBtn {
    background-color: #CE3534;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    border-radius: 70px;
    font-size: 1.2rem;
    color: white;
    text-decoration: none;
}

.mobileMenuItem {
    margin: 15px 0 0 0;
    border-style: solid;
    border-color: rgba(132, 132, 132, 0.15);
    border-width: 0;
    border-bottom-width: 1px;
    padding: 0 30px 15px;
}

.dropdownMenuConnected {
    z-index: 1000;
    background: white;
    position: fixed;
    display: flex;
    flex-direction: column;
    text-align: right;
    box-shadow: 0 20px 30px 0 rgb(0 0 0 / 10%);
    right: 0;
    border-top: 1px solid #ddd;
}

.mobileDropdownMenuConnected {
    z-index: 1000;
    background: white;
    position: fixed;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 10%);
    width: 100%;
}
