.controleBtn {
    border-radius: 100px;
    background-color: #571088;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none;
    cursor: pointer;
}

.disabledControleBtn {
    border-radius: 100px;
    background-color: #c4c4c4;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none
}


.bulle {
    margin: 5px 0;
    /*border: 0.1rem solid #6fa939;*/
    background: #f3f6f8;
    font-size: 80%;
    color: black;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
}

.bulle > .iconBulle {
    -webkit-clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    background: #6fa939;
    padding: 10px 25px 10px 20px;
    border-radius: 0.2rem 0 0 0.2rem;
    font-size: 150%;
    color: white;
    display: flex;
    align-items: center;
}

.bulle > .iconText {
    padding: 10px;
}