.defaultBtn {
    background: #571088;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: white;
    border: none;
    text-decoration: none;
    text-align: center;
}

.defaultBtn:hover {
    background: #4c0c78;
    text-decoration: none;
    cursor: pointer;
    color: white;
}

.secondaryBtn {
    border: 1px solid #571088;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: #571088;
    text-decoration: none;
    background: none;
    text-align: center;
}

.secondaryBtn:hover {
    background: #571088;
    color: white;
    border: 1px solid #571088;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 1020px) {
    .defaultBtn, .secondaryBtn {
        font-size: 1rem;
    }
}

.defaultLink{
    color: #571088;
    font-weight: 500;
    background: none;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-size: 1rem;
    text-decoration: none;
}

.defaultLink:hover {
    color: #4c0c78;
    text-decoration: underline;
    cursor: pointer;
}

.globalContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1020px;
    margin: 10px auto 50px auto;
}

@media (max-width: 1020px) {
    .globalContainer {
        margin-top: 0;
        margin-right: 10px;
        margin-left: 10px;
    }
}

.card {
    background: #f3f6f8;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
}

.cardMore{
    padding: 30px;
    flex: 1;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 20%);
    border-radius: 5px;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column
}


.bulle {
    margin: 30px 20px;
    /*border: 0.1rem solid #6fa939;*/
    background: #f3f6f8;
    font-size: 90%;
    color: black;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
}

.bulle > .iconBulle {
    -webkit-clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    background: #6fa939;
    padding: 15px 35px 15px 30px;
    border-radius: 0.2rem 0 0 0.2rem;
    font-size: 200%;
    color: white;
    display: flex;
    align-items: center;
}

.bulle > .iconText {
    padding: 15px;
}

.badge {
    border-radius: 4px;
    padding: 3px 10px;
    color: white;
}

.childCard {
    flex: 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.break {
    flex-basis: 100%;
    height: 0;
}

.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 960px) {
    .cardContainer {
        flex-direction: column;
    }
}
