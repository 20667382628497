@import url(https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Otomanopee+One&display=swap);
body {
    margin: 0;
    padding: 0;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    min-height: 100vh;
    overflow-x: hidden;
}

.noScroll{
    overflow-y: hidden;
}

html {
    min-height: 100%;
}

h1 {
    font-family: "Otomanopee One", Roboto,sans-serif;
    font-size: 200%; /*correspond à 32px)*/
    font-weight: normal;
}

@media (max-width: 1020px) {
    h1 {
        font-size: 150%;
    }
}

h2 {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 700;
    font-size: 150%;  /*correspond à 24px*/
    letter-spacing: 1px;
}

/*@media (max-width: 1020px) {*/
/*    h2 {*/
/*        font-size: 100%; !*correspond à 32px*!*/
/*    }*/
/*}*/

.Mui-selected {
    background-color: #571088 !important;
}

.Mui-error {
    color: #495057 !important;
}

.MuiOutlinedInput-notchedOutline {
    border-color: #ced4da !important;
}

.Mui-focused {
    color: #571088 !important;
}

.css-1mtsuo7 {
    z-index: 100000 !important;
}

.PersonnalInformation_personalInfoContainer__lXNtb {
    display: flex;
    justify-content: center;
    align-items: center;
}


.PersonnalInformation_personalInfoCard__fpOc2 {
    background: #F7FAFC;
    padding: 20px;
    width: 80%;
    margin: 100px 100px 50px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

@media (max-width: 800px) {
    .PersonnalInformation_personalInfoCard__fpOc2 {
        margin: 100px 20px 50px;
    }
}


.PersonnalInformation_btn__1i3Bh {
    background: #571088;
    padding: 10px 25px;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin : 10px auto;
}

.PersonnalInformation_btn__1i3Bh:hover {
    background: #4C0C78;
    cursor: pointer;
}

.PersonnalInformation_btnFemale__56gdi {
    border-radius: 25px 0 0 25px;
}

.PersonnalInformation_btnMale__3qLCR {
    border-radius: 0 25px 25px 0;
}

.PersonnalInformation_btnSelected__3MYIh {
    background: #571088;
    padding: 10px 25px;
    height: calc(2.25rem + 2px);
    color: #FFFFFF;
    border: none;
    font-size: 1rem;
    display: block;
}

.PersonnalInformation_btnNotSelected__6TkF3 {
    background: #C4C4C4;
    padding: 10px 25px;
    color: #E5E5E5;
    border: none;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    display: block;
}

.PersonnalInformation_btnFemale__56gdi:hover .PersonnalInformation_btnMale__3qLCR:hover {
    background: #4C0C78;
    cursor: pointer;
}

.PersonnalInformation_loginFooter__2S3nX {
    text-align: center;
}

.PersonnalInformation_loginFooter__2S3nX a {
    display: block;
    color: #3EA79D;
    text-decoration: none;
}

.PersonnalInformation_loginFooter__2S3nX a:hover {
    text-decoration: underline;
}



.PersonnalInformation_disabledBtn__EESQ6 {
    background-color: #C4C4C4;
    padding: 10px 25px;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin : 10px auto;
}

.Empty_empty__2zh-P{
    position: absolute;
    right: 0;
    left: 0;
    bottom: 40px;
}

.Empty_div_100__16I8d {
    min-height: 100%;
}

.Header_navbar__2zEyf {
    background: #FFFFFF;
    padding: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    margin-top: 0;
    margin-bottom: 0;
    z-index: 1000;
    position: fixed;
    width: 100%;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

/* Logo dans la navbar */

.Header_logoContainer__2aYTp{
    text-align: left;
    display: inline-block;
    height: 50px;
    padding: 15px 60px 15px 20px;
}

@media screen and (max-width: 960px) {
    .Header_logoContainer__2aYTp {
        padding: 15px 20px 15px 20px;
        flex: 1 1
    }
}

.Header_logo__1ArOK {
    aspect-ratio: 500/160;
    height: 80%;
}

@media screen and (max-width: 960px) {
    .Header_logo__1ArOK {
        width: 100%;
        object-fit: contain;
        object-position: left;
    }
}


/* Liens dans la navbar */

.Header_link__3n4HV {
    color: #000;
    font-size: 1.1rem;
    font-weight: 500;
    margin: auto 10px auto 0;
    position: relative;
    padding: 13px 8px;
    text-decoration: none;
}

.Header_link__3n4HV:hover {
    text-decoration: none;
}


.Header_link__3n4HV:hover:after {
    background-color: #CE3534;
    width: 100%;
    height: 3px;
    content: "";
    bottom: 0;
    left: 0;
    display: block;
    z-index: 2;
    position: absolute;
}

.Header_btn__OBEwx {
    padding: 10px 20px !important;
    font-size: 1.1rem !important;
}

.Header_redBtn__2cFtF {
    background-color: #CE3534;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: white;
    margin-left: 15px;
    text-decoration: none;
}

.Header_redBtn__2cFtF:hover {
    text-decoration: none;
    background-color: #C02727;
    color: white;
}

.Header_leftBtn__3n1Z2 {
    right: 20px;
    position: absolute;
}

.Header_mobileUserBtn__2c6Bb {
    height: 70% !important;
    border: none;
    background: none;
    margin-left: 20px;
    color: #571088;
    width: auto;
    padding: 5px !important;
    justify-content: center;
    align-items: center;
    display: flex;
}

@media screen and (max-width: 960px) {
    .Header_mobileUserBtn__2c6Bb {
        display: unset;
        position: relative;
    }
}

.Header_mobileUserBtn__2c6Bb:hover {
    color: #4c0c78;
}

.Header_leftBtnContainer__33Xyl {
    right: 20px;
    position: absolute;
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    .Header_leftBtnContainer__33Xyl {
        position: relative;
        flex: 1 1;
        margin-right: 15px;
        right: 0;
        justify-content: flex-end;
    }
}

.Header_menu__zeXqa {
    font-size: 1.5rem !important;
    border: none;
    background: none;
}

@media screen and (max-width: 960px) {
    .Header_menu__zeXqa {
        padding: 1px 6px;
    }
}

.Header_dropdownMenu__1ID2e {
    z-index: 1000;
    background: white;
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 10%);
}

.Header_mobileLink__27vjY {
    color: #000000;
    padding-left: 8px;
    padding-right: 8px;
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: none;
}

.Header_mobileBtn__11adt {
    padding: 10px 20px !important;
    font-size: 1.1rem !important;
}

.Header_mobileRedBtn__13aX5 {
    background-color: #CE3534;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    border-radius: 70px;
    font-size: 1.2rem;
    color: white;
    text-decoration: none;
}

.Header_mobileMenuItem__53JFT {
    margin: 15px 0 0 0;
    border-style: solid;
    border-color: rgba(132, 132, 132, 0.15);
    border-width: 0;
    border-bottom-width: 1px;
    padding: 0 30px 15px;
}

.Header_dropdownMenuConnected__3XPeJ {
    z-index: 1000;
    background: white;
    position: fixed;
    display: flex;
    flex-direction: column;
    text-align: right;
    box-shadow: 0 20px 30px 0 rgb(0 0 0 / 10%);
    right: 0;
    border-top: 1px solid #ddd;
}

.Header_mobileDropdownMenuConnected__3Xogz {
    z-index: 1000;
    background: white;
    position: fixed;
    display: flex;
    flex-direction: column;
    text-align: center;
    box-shadow: 0px 20px 30px 0px rgb(0 0 0 / 10%);
    width: 100%;
}

.Style_defaultBtn__KBzBe {
    background: #571088;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: white;
    border: none;
    text-decoration: none;
    text-align: center;
}

.Style_defaultBtn__KBzBe:hover {
    background: #4c0c78;
    text-decoration: none;
    cursor: pointer;
    color: white;
}

.Style_secondaryBtn__1AhP7 {
    border: 1px solid #571088;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: #571088;
    text-decoration: none;
    background: none;
    text-align: center;
}

.Style_secondaryBtn__1AhP7:hover {
    background: #571088;
    color: white;
    border: 1px solid #571088;
    text-decoration: none;
    cursor: pointer;
}

@media (max-width: 1020px) {
    .Style_defaultBtn__KBzBe, .Style_secondaryBtn__1AhP7 {
        font-size: 1rem;
    }
}

.Style_defaultLink__3xJB4{
    color: #571088;
    font-weight: 500;
    background: none;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-size: 1rem;
    text-decoration: none;
}

.Style_defaultLink__3xJB4:hover {
    color: #4c0c78;
    text-decoration: underline;
    cursor: pointer;
}

.Style_globalContainer__3_UId {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1020px;
    margin: 10px auto 50px auto;
}

@media (max-width: 1020px) {
    .Style_globalContainer__3_UId {
        margin-top: 0;
        margin-right: 10px;
        margin-left: 10px;
    }
}

.Style_card__1JMi8 {
    background: #f3f6f8;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 20%);
}

.Style_cardMore__1wYeB{
    padding: 30px;
    flex: 1 1;
    box-shadow: 1px 1px 4px 0 rgb(0 0 0 / 20%);
    border-radius: 5px;
    display: flex;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column
}


.Style_bulle__2E2hn {
    margin: 30px 20px;
    /*border: 0.1rem solid #6fa939;*/
    background: #f3f6f8;
    font-size: 90%;
    color: black;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
}

.Style_bulle__2E2hn > .Style_iconBulle__QlmN2 {
    -webkit-clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    background: #6fa939;
    padding: 15px 35px 15px 30px;
    border-radius: 0.2rem 0 0 0.2rem;
    font-size: 200%;
    color: white;
    display: flex;
    align-items: center;
}

.Style_bulle__2E2hn > .Style_iconText__2TV3G {
    padding: 15px;
}

.Style_badge__2drMM {
    border-radius: 4px;
    padding: 3px 10px;
    color: white;
}

.Style_childCard__M1TKd {
    flex: 1 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Style_break__baHbn {
    flex-basis: 100%;
    height: 0;
}

.Style_cardContainer__2xNue {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 960px) {
    .Style_cardContainer__2xNue {
        flex-direction: column;
    }
}

.Footer_title__3StrP {
    color: #CE3534;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: 20px
}

.Footer_item__2-yvu {
    padding-left: 6px;
    padding-bottom: 5px;
    font-size: 1.1rem;
    margin: 5px auto;
    color: #DDDDDD;
    text-decoration: none;
}

.Footer_link__3DZGt {
    color: white;
    text-decoration: none;
}

.Footer_link__3DZGt:hover {
    color: #DDDDDD;
    text-decoration: none;
}

.Footer_itemLegal__3GBda {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10px
}

.Footer_linkLegal__375Ab {
    color: white;
    text-decoration: none;
    font-weight: 500;
}

.Footer_linkLegal__375Ab:hover {
    color: #DDDDDD;
    text-decoration: none;
    font-weight: 500;
}

.Footer_icon__2Q4NA {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    font-size: 20px
}

.Footer_icon__2Q4NA:hover {
    color: #DDDDDD;
}

.Footer_footer__5btx5 {
    padding: 10px;
    color: white;
    font-size: 0.85rem;
    flex: 1 1;
}

@media (max-width: 780px) {
    .Footer_footer__5btx5 {
        text-align: center !important;
        font-size: 0.85rem;
    }
}

.Footer_footerContainer__1kxnY {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
}

@media (max-width: 780px) {
    .Footer_footerContainer__1kxnY {
        flex-direction: column;
    }
}


.Footer_appContainer__2rzIA {
    background: #f7fafc;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 5px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: none;
}

.Footer_appContainer__2rzIA {
    background: white;
}

.Footer_container__2YQB- {
    display: flex;
    flex-direction: row;
    flex: 1 1;
}

@media (max-width: 1200px) {
    .Footer_container__2YQB- {
        flex-direction: column;
    }
}

.Footer_fullContainer__jHTdw {
    width: 1140px;
    display: flex;
    padding-bottom: 40px;
    flex-direction: row;
}

@media (max-width: 1200px) {
    .Footer_fullContainer__jHTdw {
        width: 100%;
    }
}

@media (max-width: 770px) {
    .Footer_fullContainer__jHTdw {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.Footer_legalNetwork__XLnsu {
    display: flex;
    flex-direction: row;
}

@media (max-width: 770px) {
    .Footer_legalNetwork__XLnsu {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.Footer_network__1nSLv {
    align-self: flex-end;
    padding: 10px 10px 20px;
}

@media (max-width: 770px) {
    .Footer_network__1nSLv {
        align-self: center;
    }
}

.Footer_legal__trff8 {
    flex: 1 1;
    display: flex;
    padding: 10px;
    flex-direction: row;
}

@media (max-width: 770px) {
    .Footer_legal__trff8 {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.Footer_divider__1ZvI3 {
    border: solid 1px #CE3534;
    width: 100%;
}

@media (max-width: 770px) {
    .Footer_divider__1ZvI3 {
        border: solid 1px #CE3534;
        width: 50%;
        left: 25%;
        position: relative;
    }
}

.Footer_legalNetworkFooter__5tdc5 {
    width: 1140px;
    display: flex;
    flex-direction: column
}

@media (max-width: 1200px) {
    .Footer_legalNetworkFooter__5tdc5 {
        width: 100%;
    }
}
.Flash_flash__7I6pH {
    z-index: 1000;
    position: fixed;
    text-align: center;
    right: 1%;
    width: 33%;
}

@media (max-width: 1200px) {
    .Flash_flash__7I6pH {
        width: 50%;
    }
}

@media (max-width: 750px) {
    .Flash_flash__7I6pH {
        width: 90%;
        margin: 0 auto;
    }
}

.Flash_bulle__2p0FE {
    margin: 30px 20px;
    /*border: 0.1rem solid;*/
    background: #f3f6f8;
    font-size: 90%;
    color: black;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 90px auto;
}

.Flash_bulle__2p0FE > .Flash_iconBulle__2Ddew {
    display: flex;
    align-items: center;
    -webkit-clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    padding: 15px 25px 15px 20px;
    border-radius: 0.2rem 0 0 0.2rem;
    font-size: 200%;
    color: white;
    width: 30px;
    text-align: center;
}

.Flash_bulle__2p0FE > .Flash_iconText__3xd-k {
    padding: 15px;
    display: flex;
    align-items: center;
}

.FormStyle_inputLine__Wq5BM{
    display: flex;
}

@media (max-width: 1000px) {
    .FormStyle_inputLine__Wq5BM {
        flex-direction: column;
    }
}


.FormStyle_inputGroup__2xUoj {
    flex: 1 1;
    margin: 10px 20px;
}

.FormStyle_input__2P7km {
    display: inline-block;
    flex: 12 1;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    background-color: white !important;
}

@media (max-width: 800px) {
    .FormStyle_input__2P7km {
        flex: 7 1;
    }
}

.FormStyle_input__2P7km:focus {
    outline: none;
}

.FormStyle_inputIcon__5vCjT {
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.FormStyle_inputField__3SkJm {
    flex: 1 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    height: calc(2.25rem + 5px);
    margin-top: 5px;
}

.FormStyle_eyeIcon__3b7Lc {
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    color: #495057;
}

.FormStyle_iconHelp__Qfm_2 {
    margin-left: 5px;
    color: #888;
}


.FormStyle_inputGroupBirthGender__1f7bF{
    flex: 1 1;
    display: flex;
    flex-direction: row;
    margin: auto 20px;
}

@media (max-width: 800px) {
    .FormStyle_inputGroupBirthGender__1f7bF {
        flex-direction: column;
    }
}


.FormStyle_inputGroupBirth__24XoN {
    flex: 3 1;
    margin: 10px 0 10px 20px;
}

@media (max-width: 800px) {
    .FormStyle_inputGroupBirth__24XoN {
        margin-left: 0;
    }
}



.FormStyle_inputFieldBirth__z_6wD {
    box-sizing: border-box;
    position: relative;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    margin: 5px 0 0;
    height: calc(2.25rem + 5px);
}

.FormStyle_inputContent__3iMzr{
    display: flex;
    flex: 1 1;
    margin: 0;
    height: calc(2.25rem + 5px);
    justify-content: center;
    align-items: center;
}

.FormStyle_inputDateContainer__Orc5b {
    flex: 12 1;
}

.FormStyle_inputDate__3oZgY {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    background-color: white !important;
}

.FormStyle_inputDate__3oZgY:focus{
    outline: none;
}

.FormStyle_textDanger__3mrML{
    color: red;
    margin-left: 20px;
    margin-top: -10px;
}

.FormStyle_disabledBtn__3wWcf {
    background: #C4C4C4;
    font-family:-apple-system,BlinkMacSystemFont,"Segoe UI","Roboto",sans-serif;
    font-weight: 500;
    padding: 10px 30px;
    border-radius: 70px;
    font-size: 1.2rem;
    height:auto;
    color: white;
    border: none;
    text-decoration: none;
}

.FormStyle_defaultBtn__3PqrV:hover {
    background: #C4C4C4;
    text-decoration: none;
    cursor: pointer;
    color: white;
}

.FormStyle_inputLineSame__1O5sX{
    display: flex;
}

.FormStyle_checkbox__1yoWl {
    margin: 0 25px;
    height: 15px;
    width: 15px;
    border: #1C4CBD !important;
    background-color: #1C4CBD !important;
}

input[type=checkbox] {
    cursor: pointer;
    visibility: hidden;
    height: 15px;
    width: 15px;
    -moz-appearance:initial;
}

input[type=checkbox]::after {
    content: " ";
    background-color: #fff;
    color: #3EA79D;
    visibility: visible;
    border: 1px solid #C4C4C4;
    border-radius: 5px;
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

input[type=checkbox]:checked:after {
    content: "\2714";
    font-weight: bold;
}

/** Boutons pour choisir le sexe **/

.FormStyle_inputGroupGender__3pvTr {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    margin: 10px 0;
}

.FormStyle_gender__2HYUl {
    flex: 1 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    height: calc(2.25rem + 5px);
    justify-content: flex-start;
    align-items: center;
    margin: 0;
}

.FormStyle_btnSelected__11HxI {
    background: #571088;
    padding: 10px 25px;
    height: calc(2.25rem + 2px);
    color: #FFFFFF;
    border: none;
    font-size: 1rem;
    display: block;
}

.FormStyle_btnNotSelected__238gr {
    background: #C4C4C4;
    padding: 10px 25px;
    color: #E5E5E5;
    border: none;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    display: block;
}

.FormStyle_btnFemale__3mzGi{
    border-radius: 25px 0 0 25px;
}

.FormStyle_btnMale__3nLwO{
    border-radius: 0 25px 25px 0;
}

.FormStyle_btnFemale__3mzGi:hover .FormStyle_btnMale__3nLwO:hover {
    background: #4C0C78;
    cursor: pointer;
}


/** Bouton à la fin du formulaire **/

.FormStyle_btnContainer__3zb7u {
    display: flex;
    justify-content: center;
    align-items: center
}

.FilAriane_mainContainer__z3aH3 {
    width: 100%;
    background: #f3f6f8;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center
}

/*@media (max-width: 1020px) {*/
/*    .mainContainer {*/
/*        display: none;*/
/*    }*/
/*}*/

.FilAriane_contentContainer__3LWHn {
    width: 1020px;
    margin: 5px 15px 15px;
}

@media (max-width: 1020px) {
    .FilAriane_contentContainer__3LWHn {
        width: calc(100% - 30px);
    }
}

.Shipping_shippingContainer__3u9eZ {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 100px;
    margin-bottom: 50px;
}

.Shipping_previousContainer__yevRX {
    display: flex;
    width: 80%;
    align-items: flex-start;
    padding: 20px;
}

.Shipping_previousBtn__nUTnv {
    cursor: pointer;
    position: relative;
    color: #555;
    font-family: Quicksand, Roboto, sans-serif;
    background-color: transparent;
    border: none;
}

.Shipping_previousIcon__2NQZa {
    margin-right: 5px;
}


.Shipping_shippingCard__23brB {
    background: #F7FAFC;
    padding: 20px;
    width: 80%;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}


.Shipping_inputLine__3rq47{
    display: flex;
}

@media (max-width: 1000px) {
    .Shipping_inputLine__3rq47 {
        flex-direction: column;
    }
}

.Shipping_inputLineSame__2LyGU{
    display: flex;
}

.Shipping_inputContent__3F5mI{
    display: flex;
    flex: 1 1;
    margin: 20px;
    height: calc(2.25rem + 5px);
    justify-content: center;
    align-items: center;
}

.Shipping_inputDateContainer__1ktoc {
    flex: 12 1;
}

.Shipping_inputDate__j0Jvm {
    width: 100%;
    display: inline-block;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    background-color: white !important;
}

.Shipping_textDanger__3lKbt{
    color: red;
    margin-left: 20px;
    margin-top: -10px;
}

.Shipping_inputDate__j0Jvm:focus{
    outline: none;
}

.Shipping_inputField__3OS7S {
    flex: 1 1;
    box-sizing: border-box;
    position: relative;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
    height: calc(2.25rem + 5px);
    margin-top: 5px;
}

.Shipping_inputGroup__2lIm4{
    flex: 1 1;
    margin: 10px 20px;
}

.Shipping_input__3ntU_ {
    display: inline-block;
    flex: 12 1;
    box-sizing: border-box;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    line-height: 1.5;
    border: none;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    background-color: white !important;
}

@media (max-width: 800px) {
    .Shipping_input__3ntU_ {
        flex: 7 1 !important;
    }
}

.Shipping_input__3ntU_:focus {
    outline: none;
}

.Shipping_inputIcon__3U3D9 {
    flex: 1 1;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.Shipping_btn__XS0dx {
    background: #51276C;
    padding: 10px 25px;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin: 20px auto 10px;
}

.Shipping_btn__XS0dx:hover {
    background: #4C0C78;
    cursor: pointer;
}

.Shipping_disabledBtn__CMEcM {
    background-color: #C4C4C4;
    padding: 10px 25px;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin : 10px auto;
}

.Shipping_btnFemale__3osBB {
    border-radius: 25px 0 0 25px;
}

.Shipping_btnMale__eMiGc {
    border-radius: 0 25px 25px 0;
}

.Shipping_btnSelected__1ePO5 {
    background: #51276C;
    padding: 10px 25px;
    height: calc(2.25rem + 2px);
    color: #FFFFFF;
    border: none;
    font-size: 1rem;
    display: block;
}

.Shipping_btnNotSelected__YwJXE {
    background: #C4C4C4;
    padding: 10px 25px;
    color: #E5E5E5;
    border: none;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    display: block;
}




.Welcome_leftContainer__o6izK {
    background-image: url(/static/media/atorika_box.93247e4f.png);
    flex: 0.8 1;
    background-size:contain;

}
@media (max-width: 1020px) {
    .Welcome_leftContainer__o6izK {
        display: none
    }
}

.Welcome_welcomeContainer__3xN2y {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 100px;
    max-width: 1020px;
}

@media (max-width: 1020px) {
    .Welcome_welcomeContainer__3xN2y {
        margin-top: 10px;
    }
}

.Welcome_rightContainer__W9vIX {
    flex: 1 1;
    padding-left: 40px
}

@media (max-width: 1020px) {
    .Welcome_rightContainer__W9vIX {
        padding-left: 15px;
        padding-right: 15px;
    }
}


.Welcome_inputGroup__12EHc {
    margin-left: 0;
    margin-right: 0;
}

.Welcome_checkbox__1N86v {
    margin: 0 10px 0 0;
}

.Login_loginContainer__2TZrJ {
    height: 90vh;
    margin-top: 80px;
}

/* card */

.Login_loginCard__2F22D {
    padding: 20px;
    width: 500px;
    margin: 100px 0 50px;
    top: -30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 800px) {
    .Login_loginCard__2F22D {
        width: 80%;
    }
}

.Login_loginBtn__1ZMTe {
    margin : 20px auto;
}

.Login_linkUsePwd__3e0_- {
    margin-bottom: 40px;
}

.Login_linkRegister__3Gkca{
    margin: 5px auto;
}


/* footer */
.Login_footer__mDdDz {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* message d'erreur */
.Login_error__1a-5b {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #CE3534;
}

.Login_inputGroup__dzAqz {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
}

.Home_homeContainer__1Zn4C {
    flex-direction: column;
}

@media (max-width: 960px) {
    .Home_homeContainer__1Zn4C {
        padding: 10px;
    }
}

.Home_title__36VT4 {
    align-self: flex-start;
    margin-bottom: 30px
}

.Home_cardContainer__3lWY3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 960px) {
    .Home_cardContainer__3lWY3 {
        flex-direction: column;
    }
}

.Home_addressContainer__1IZXa {
    flex: 1 1;
}

@media (max-width: 960px) {
    .Home_addressContainer__1IZXa {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}


.Home_childCard__2ByJf {
    flex: 1 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Home_break__1EDQh {
    flex-basis: 100%;
    height: 0;
}

.NotFound_container__1v_WT {
    margin-top: 80px;
}

.NotFound_subContainer__TMWvo {
    margin: 10px;
    display: flex;
    flex-direction: row;
}

@media (max-width: 800px) {
    .NotFound_subContainer__TMWvo {
        flex-direction: column;
    }
}

.NotFound_robot__1edua {
    flex: 2 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.NotFound_infos__9-zxz {
    flex: 3 1;
    padding-left: 20px;
    padding-bottom: 30px;
}

.NotFound_title__7GBuI {
    font-size: 6rem;
    margin-bottom: 50px;
}

.NotFound_subTitle__3d2PE {
    font-size: 2rem;
}

.NotFound_text__1lBqr {
    margin: 40px auto;
    font-size: 1.2rem;
}

.Profile_homeContainer__cNbUF {
    flex-direction: column;
}

@media (max-width: 960px) {
    .Profile_homeContainer__cNbUF {
        padding: 10px;
    }
}

.Profile_title__2fmPa {
    align-self: flex-start;
    margin-bottom: 30px
}

.Profile_cardContainer__1ywhW {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 960px) {
    .Profile_cardContainer__1ywhW {
        flex-direction: column;
    }
}

.Profile_addressContainer__2Ykfu {
    flex: 1 1;
}

@media (max-width: 960px) {
    .Profile_addressContainer__2Ykfu {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}


.Profile_childCard__p5ptM {
    flex: 1 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.Profile_break__12fxS {
    flex-basis: 100%;
    height: 0;
}

.Code_loginContainer__3S1oB {
    height: 90vh;
    margin-top: 80px;
}

/* card */

.Code_loginCard__31PmJ {
    padding: 20px;
    width: 500px;
    margin: 100px 0 50px;
    top: -30%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media (max-width: 800px) {
    .Code_loginCard__31PmJ {
        width: 80%;
    }
}

.Code_loginBtn__2oGgX {
    margin : 20px auto;
}

.Code_linkUsePwd__1j72u {
    margin-bottom: 40px;
}

.Code_linkRegister__OSV7f{
    margin: 5px auto;
}


/* footer */
.Code_footer__2JWco {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

/* message d'erreur */
.Code_error__3KheX {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #CE3534;
    margin: 10px 0;
}

.Code_inputGroup__3005G {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    background-color: #fff;
    color: #495057;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    box-shadow: inset 0 0 0 transparent;
}

.ListAddress_cardContainer__3hOz3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 1020px) {
.ListAddress_cardContainer__3hOz3 {
        flex-direction: column;
    }
}

.ListAddress_break__dTEaB {
    flex-basis: 100%;
    height: 0;
}

.ListAddress_childCard__mLO7x {
    flex: 1 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ListAddress_title__29UwJ {
    align-self: flex-start;
    margin-bottom: 30px
}
.CardTemplateBasket_controleBtn__3K-P5 {
    border-radius: 100px;
    background-color: #571088;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none;
    cursor: pointer;
}

.CardTemplateBasket_disabledControleBtn__jEKYZ {
    border-radius: 100px;
    background-color: #c4c4c4;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none
}


.CardTemplateBasket_bulle__13Lp2 {
    margin: 5px 0;
    /*border: 0.1rem solid #6fa939;*/
    background: #f3f6f8;
    font-size: 80%;
    color: black;
    border-radius: 0.5rem;
    display: grid;
    grid-template-columns: 1fr auto;
}

.CardTemplateBasket_bulle__13Lp2 > .CardTemplateBasket_iconBulle__1ttos {
    -webkit-clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    clip-path: polygon(-1px -1px,0 100%,80% 100%,100% 50%,80% -1px);
    background: #6fa939;
    padding: 10px 25px 10px 20px;
    border-radius: 0.2rem 0 0 0.2rem;
    font-size: 150%;
    color: white;
    display: flex;
    align-items: center;
}

.CardTemplateBasket_bulle__13Lp2 > .CardTemplateBasket_iconText__1vL9_ {
    padding: 10px;
}
.ListUser_cardContainer__1WX5T {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 1020px) {
.ListUser_cardContainer__1WX5T {
        flex-direction: column;
    }
}

.ListUser_break__341c0 {
    flex-basis: 100%;
    height: 0;
}

.ListUser_childCard__1x6Ah {
    flex: 1 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.ListUser_title__17KKN {
    align-self: flex-start;
    margin-bottom: 30px
}

.ListUser_controleBtn__3nvIB {
    border-radius: 100px;
    background-color: #571088;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none
}

.ListUser_disabledControleBtn__1MKhG {
    border-radius: 100px;
    background-color: #c4c4c4;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none
}
.ResumeBasket_scroll__3LgiW::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #ddd;
    border-radius: 5px;
}

.ResumeBasket_scroll__3LgiW::-webkit-scrollbar-thumb {
    background: #571088;
    border-radius: 5px;
}
.Payement_cardContainer__3sxKv {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

.Payement_subContainer__QRqpS {
    margin: 10px;
    display: flex;
}

@media (max-width: 800px) {
    .Payement_subContainer__QRqpS {
        flex-direction: column;
    }
}

.Payement_infos__12gdc {
    padding-left: 20px;
    display: flex;
    justify-content: center;
}

.Payement_title__1U-3W {
    align-self: flex-start;
    margin-bottom: 30px
}

.Payement_text__1V1Eh {
    margin: 40px auto;
    font-size: 1.2rem;
}

.ListPoints_listpoints__3MdrI::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background-color: #ddd;
    border-radius: 5px;
}

.ListPoints_listpoints__3MdrI::-webkit-scrollbar-thumb {
    background: #571088;
    border-radius: 5px;
}

.Delivery_cardContainer__VfQ6X {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px 0;
}
.Box_cardContainer__2J8W3 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

@media (max-width: 960px) {
    .Box_cardContainer__2J8W3 {
        grid-template-columns: repeat(1, 1fr);
    }
}

.CardStats_stats__1r6TB {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    max-width: 200px;
}

.CardStats_statsStar__2WpWU {
    background: url(/static/media/Stats_star.87c11ab6.svg) no-repeat;
    aspect-ratio: 356/200;
    background-size: contain;
}

.CardStats_statsCheck__3Aw2_ {
    background: url(/static/media/Stats_check.53e089ee.svg) no-repeat;
    aspect-ratio: 354/196;
    background-size: contain;
}

.CardStats_statsReward__2r493 {
    background: url(/static/media/Stats_reward.46809ddc.svg) no-repeat;
    aspect-ratio: 354/196;
    background-size: contain;
}

.CardStats_statsQuiz__273yz {
    background: url(/static/media/Stats_ask.efcf6106.svg) no-repeat;
    aspect-ratio: 354/196;
    background-size: contain;
}

.CardStats_statsDigit__26NHX {
    font-size: 1.7rem;
    text-align: center;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 20px;
}

@media (max-width: 960px) {
    .CardStats_statsDigit__26NHX {
        font-size: 1.7rem;
    }
}

.CardStats_statsName__1376c {
    font-size: 0.8rem;
    text-align: center;
    margin-left: 35px;
    margin-right: 20px;
    color: #C4C4C4;
    font-weight: 300;
}

@media (max-width: 565px) {
    .CardStats_statsName__1376c {
        font-size: 0.9rem;
    }
}

.CardStats_badge__34bk7 {
    aspect-ratio: 1;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    width: 100%;
    margin: auto
}

.CardStats_badgeName__1PQMm {
    margin-top: 5px;
    font-size: 1rem;
    text-align: center
}

.CardStats_badgeInterr__33oZq {
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0;
}

.CardStats_badgeContainer__27bFj {
    flex: 1 1;
    margin: 0 15px;
    flex-direction: column;
    align-items: center;
}

