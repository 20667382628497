.title {
    color: #CE3534;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.2em;
    margin-bottom: 20px
}

.item {
    padding-left: 6px;
    padding-bottom: 5px;
    font-size: 1.1rem;
    margin: 5px auto;
    color: #DDDDDD;
    text-decoration: none;
}

.link {
    color: white;
    text-decoration: none;
}

.link:hover {
    color: #DDDDDD;
    text-decoration: none;
}

.itemLegal {
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10px
}

.linkLegal {
    color: white;
    text-decoration: none;
    font-weight: 500;
}

.linkLegal:hover {
    color: #DDDDDD;
    text-decoration: none;
    font-weight: 500;
}

.icon {
    margin-left: 15px;
    margin-right: 15px;
    color: white;
    font-size: 20px
}

.icon:hover {
    color: #DDDDDD;
}

.footer {
    padding: 10px;
    color: white;
    font-size: 0.85rem;
    flex: 1;
}

@media (max-width: 780px) {
    .footer {
        text-align: center !important;
        font-size: 0.85rem;
    }
}

.footerContainer {
    display: flex;
    margin-top: 10px;
    flex-direction: row;
}

@media (max-width: 780px) {
    .footerContainer {
        flex-direction: column;
    }
}


.appContainer {
    background: #f7fafc;
    margin-right: 10px;
    margin-left: 10px;
    border-radius: 5px;
    padding: 4px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    text-decoration: none;
}

.appContainer {
    background: white;
}

.container {
    display: flex;
    flex-direction: row;
    flex: 1;
}

@media (max-width: 1200px) {
    .container {
        flex-direction: column;
    }
}

.fullContainer {
    width: 1140px;
    display: flex;
    padding-bottom: 40px;
    flex-direction: row;
}

@media (max-width: 1200px) {
    .fullContainer {
        width: 100%;
    }
}

@media (max-width: 770px) {
    .fullContainer {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.legalNetwork {
    display: flex;
    flex-direction: row;
}

@media (max-width: 770px) {
    .legalNetwork {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.network {
    align-self: flex-end;
    padding: 10px 10px 20px;
}

@media (max-width: 770px) {
    .network {
        align-self: center;
    }
}

.legal {
    flex: 1;
    display: flex;
    padding: 10px;
    flex-direction: row;
}

@media (max-width: 770px) {
    .legal {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
}

.divider {
    border: solid 1px #CE3534;
    width: 100%;
}

@media (max-width: 770px) {
    .divider {
        border: solid 1px #CE3534;
        width: 50%;
        left: 25%;
        position: relative;
    }
}

.legalNetworkFooter {
    width: 1140px;
    display: flex;
    flex-direction: column
}

@media (max-width: 1200px) {
    .legalNetworkFooter {
        width: 100%;
    }
}