.personalInfoContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}


.personalInfoCard {
    background: #F7FAFC;
    padding: 20px;
    width: 80%;
    margin: 100px 100px 50px;
    border-radius: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
}

@media (max-width: 800px) {
    .personalInfoCard {
        margin: 100px 20px 50px;
    }
}


.btn {
    background: #571088;
    padding: 10px 25px;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin : 10px auto;
}

.btn:hover {
    background: #4C0C78;
    cursor: pointer;
}

.btnFemale {
    border-radius: 25px 0 0 25px;
}

.btnMale {
    border-radius: 0 25px 25px 0;
}

.btnSelected {
    background: #571088;
    padding: 10px 25px;
    height: calc(2.25rem + 2px);
    color: #FFFFFF;
    border: none;
    font-size: 1rem;
    display: block;
}

.btnNotSelected {
    background: #C4C4C4;
    padding: 10px 25px;
    color: #E5E5E5;
    border: none;
    height: calc(2.25rem + 2px);
    font-size: 1rem;
    display: block;
}

.btnFemale:hover .btnMale:hover {
    background: #4C0C78;
    cursor: pointer;
}

.loginFooter {
    text-align: center;
}

.loginFooter a {
    display: block;
    color: #3EA79D;
    text-decoration: none;
}

.loginFooter a:hover {
    text-decoration: underline;
}



.disabledBtn {
    background-color: #C4C4C4;
    padding: 10px 25px;
    font-family: 'Quicksand', 'Roboto', sans-serif;
    color: #FFFFFF;
    border: none;
    border-radius: 25px;
    height: 40px;
    font-size: 1rem;
    display: block;
    margin : 10px auto;
}
