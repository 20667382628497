.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 1020px) {
.cardContainer {
        flex-direction: column;
    }
}

.break {
    flex-basis: 100%;
    height: 0;
}

.childCard {
    flex: 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.title {
    align-self: flex-start;
    margin-bottom: 30px
}