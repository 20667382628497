.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 1020px) {
.cardContainer {
        flex-direction: column;
    }
}

.break {
    flex-basis: 100%;
    height: 0;
}

.childCard {
    flex: 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.title {
    align-self: flex-start;
    margin-bottom: 30px
}

.controleBtn {
    border-radius: 100px;
    background-color: #571088;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none
}

.disabledControleBtn {
    border-radius: 100px;
    background-color: #c4c4c4;
    color:  white;
    height: 20px;
    aspect-ratio: 1;
    text-align: center;
    margin: auto 10px;
    border: none
}