.mainContainer {
    width: 100%;
    background: #f3f6f8;
    border-bottom: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center
}

/*@media (max-width: 1020px) {*/
/*    .mainContainer {*/
/*        display: none;*/
/*    }*/
/*}*/

.contentContainer {
    width: 1020px;
    margin: 5px 15px 15px;
}

@media (max-width: 1020px) {
    .contentContainer {
        width: calc(100% - 30px);
    }
}
