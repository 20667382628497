.homeContainer {
    flex-direction: column;
}

@media (max-width: 960px) {
    .homeContainer {
        padding: 10px;
    }
}

.title {
    align-self: flex-start;
    margin-bottom: 30px
}

.cardContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 10px
}

@media (max-width: 960px) {
    .cardContainer {
        flex-direction: column;
    }
}

.addressContainer {
    flex: 1;
}

@media (max-width: 960px) {
    .addressContainer {
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
}


.childCard {
    flex: 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
}

.break {
    flex-basis: 100%;
    height: 0;
}
