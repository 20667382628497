.container {
    margin-top: 80px;
}

.subContainer {
    margin: 10px;
    display: flex;
    flex-direction: row;
}

@media (max-width: 800px) {
    .subContainer {
        flex-direction: column;
    }
}

.robot {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}

.infos {
    flex: 3;
    padding-left: 20px;
    padding-bottom: 30px;
}

.title {
    font-size: 6rem;
    margin-bottom: 50px;
}

.subTitle {
    font-size: 2rem;
}

.text {
    margin: 40px auto;
    font-size: 1.2rem;
}
