.stats {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    max-width: 200px;
}

.statsStar {
    background: url("../../assets/stats/Stats_star.svg") no-repeat;
    aspect-ratio: 356/200;
    background-size: contain;
}

.statsCheck {
    background: url("../../assets/stats/Stats_check.svg") no-repeat;
    aspect-ratio: 354/196;
    background-size: contain;
}

.statsReward {
    background: url("../../assets/stats/Stats_reward.svg") no-repeat;
    aspect-ratio: 354/196;
    background-size: contain;
}

.statsQuiz {
    background: url("../../assets/stats/Stats_ask.svg") no-repeat;
    aspect-ratio: 354/196;
    background-size: contain;
}

.statsDigit {
    font-size: 1.7rem;
    text-align: center;
    margin-top: 20px;
    margin-left: 35px;
    margin-right: 20px;
}

@media (max-width: 960px) {
    .statsDigit {
        font-size: 1.7rem;
    }
}

.statsName {
    font-size: 0.8rem;
    text-align: center;
    margin-left: 35px;
    margin-right: 20px;
    color: #C4C4C4;
    font-weight: 300;
}

@media (max-width: 565px) {
    .statsName {
        font-size: 0.9rem;
    }
}

.badge {
    aspect-ratio: 1;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    width: 100%;
    margin: auto
}

.badgeName {
    margin-top: 5px;
    font-size: 1rem;
    text-align: center
}

.badgeInterr {
    text-align: center;
    font-weight: 500;
    font-size: 1.5rem;
    margin: 0;
}

.badgeContainer {
    flex: 1;
    margin: 0 15px;
    flex-direction: column;
    align-items: center;
}
