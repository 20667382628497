.leftContainer {
    background-image: url("../../../assets/utils/atorika_box.png");
    flex: 0.8;
    background-size:contain;

}
@media (max-width: 1020px) {
    .leftContainer {
        display: none
    }
}

.welcomeContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto 100px;
    max-width: 1020px;
}

@media (max-width: 1020px) {
    .welcomeContainer {
        margin-top: 10px;
    }
}

.rightContainer {
    flex: 1;
    padding-left: 40px
}

@media (max-width: 1020px) {
    .rightContainer {
        padding-left: 15px;
        padding-right: 15px;
    }
}


.inputGroup {
    margin-left: 0;
    margin-right: 0;
}

.checkbox {
    margin: 0 10px 0 0;
}
